import React from "react"
import { GatsbyTransitionLinkProps, PageContext } from "../types"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"
import { Page } from "../components/page"

const PageTemplate = (props: PageProps<{ prismicPage: GatsbyTypes.PrismicPage }, PageContext> & GatsbyTransitionLinkProps) => {
  return (
    <Page {...props} />
  )
}

const PageTemplateWithPreview = withPrismicPreview(PageTemplate, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
}])

export default PageTemplateWithPreview

export const PageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      ...PrismicPage
    }
  }
`

export const prismicPageFragment = graphql`
  fragment PrismicPage on PrismicPage {
    _previewable
    data {
      body {
        ... on PrismicPageDataBodyColumn {
          id
          slice_type
          primary {
            text {
              html
              raw
              text
            }
          }
        }
      }
      preamble {
        html
        raw
        text
      }
      title
    }
    id
    prismicId
    type
    uid
  }
`
