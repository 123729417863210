import React from "react"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"
import { RichText } from "prismic-reactjs"
import { PrismicLink } from "./prismicLink"
import { useTransitionParameters } from "../hooks/useTransitionParameters"
import { GatsbyTransitionLinkProps, PageContext } from "../types"

const Page = ({
  data: { 
    prismicPage
  },
  entry,
  exit,
  transitionStatus,
}: PageProps<{ prismicPage: GatsbyTypes.PrismicPage }, PageContext> & GatsbyTransitionLinkProps): JSX.Element | null => {
  if (!prismicPage?.data) return null

  const data = prismicPage.data,
    header = data.preamble?.raw && (
      <div>
        <RichText
          render={data.preamble.raw}
          serializeHyperlink={PrismicLink}
        />
      </div>
    ),
    {
      headerTransitionClasses,
      headerTransitionStyles,
      mainTransitionClasses,
      mainTransitionStyles,
    } = useTransitionParameters({
      entry: entry,
      exit: exit,
      transitionStatus: transitionStatus,
    })

  const firstColumnContent = data.body && data.body[0] && data.body[0]?.primary?.text?.raw,
    firstParagraphText = firstColumnContent && (firstColumnContent as Record<string, any>[])[1]?.text,
    description = firstParagraphText ?? ``

  return (
    <div className="page">
      <Helmet>
        <title>{data.title}</title>
        <meta name="twitter:title" content={data.title} />
        <meta property="og:title" content={data.title} />
        <meta name="description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <header
        className={`hidden lg:block fixed z-10 top-0 w-72 mt-48 ml-7 ${headerTransitionClasses}`}
        style={headerTransitionStyles}
      >
        {header}
      </header>
      <div className={mainTransitionClasses} style={mainTransitionStyles}>
        <div className="-max-w-12 w-col md:w-1/2 mx-auto md:mx-7">
          <header className="block lg:hidden w-72 -max-w-12 mt-32 mb-32">
            {header}
          </header>
        </div>
        <main className="flex flex-wrap space-evenly lg:-max-w-80 mt-48 mx-auto">
          {data.body?.map((slice) => {
            let primary
            switch (slice?.slice_type) {
              case `column`:
                primary = slice?.primary
                return primary?.text?.raw ? (
                  <div
                    className={`-max-w-12 md:max-w-none mx-auto w-col md:w-1/2 min-w-72 md:px-7 mb-24`}
                  >
                    <RichText
                      render={primary.text.raw}
                      serializeHyperlink={PrismicLink}
                    />
                  </div>
                ) : null
              default:
                return null
            }
          })}
        </main>
      </div>
    </div>
  )
}

export default Page
export { Page }
